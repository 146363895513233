<template>
  <div class="about">
    <div class="aboutTop">
      <Breadcrumb separator=">" class="bread">
        <BreadcrumbItem to="/" class="textColorO">首页</BreadcrumbItem>
        <BreadcrumbItem class="textColor">关于韬儒</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <img src="@/assets/images/banner.png" alt="" class="aboutBan" />
    <div class="aboutTxt">关于韬儒</div>
    <div class="aboutBox">
      <div class="aboutBoxMain">
        <div class="aboutBoxMainLeft">
          <div class="aboutBoxMainLeftTop">关于韬儒</div>
         
            <div
              class="aboutBoxMainLeftmain mainstyle"
              v-for="(item, index) in aboutBackList"
              :key="index"
              @click="aboutBackClick(index)"
            >
              <img
                v-if="aboutBackNum == index"
                src="@/assets/images/aboutBack.png"
                class="aboutBack"
              />
              <img
                v-else
                src="@/assets/images/aboutBack1.png"
                class="aboutBack"
              />
              <!-- <img :src="(aboutBackNum == index) ? '@/assets/images/aboutBack.png':'@/assets/images/aboutBack1.png'" class="aboutBack"> -->
              <p
                class="mainstyle"
                :style="
                  aboutBackNum == index && 'font-weight: 600;color: #299654;'
                "
              >
                {{ item }}
              </p>
            </div>
          </keep-alive>

          <p class="zxPhone">
            <icon class="iconfont icondianhua"></icon>咨询热线
          </p>
          <p class="zxPhoneNum">400-119-0811</p>
        </div>
        <div
          class="aboutBoxMainRight"
          v-if="aboutBackNum == 0 && $route.name == '关于韬儒'"
        >
          <div class="aboutBoxMainRightBox">
            <div class="aboutBoxMainRightText">
              <p>韬儒简介</p>
              {{ staticData.articleIntro }}
              <p v-html="staticData.articleContent"></p>
            </div>
            <img :src="staticData.articleThumb" />
          </div>
          <!-- <div class="aboutBoxMainRightBox">
            <div class="aboutBoxMainRightText">
              <p>韬儒文化</p>
              <p>
                公墓落于北京市平谷区，建造于大山之间，背靠燕山山脉，面朝水库，西有马鞍山，东有笔架山，明堂开阔。园区内保持着相对原始的自然环境，山坡之上柿子、红宵梨、山桃板栗等果木啥程度树公墓落于北京市平谷区，建造于大山之间，背靠燕山山脉，面朝水库，西有马鞍山，东有笔架山，明堂开阔。园区内保持着相对原始的自然环境，山坡之上柿子、红宵梨、山桃板栗等果木啥程度树公墓落于北京市平谷区，建造于大山之间，背靠燕山山脉，面朝水库，西有马鞍山，东有笔架山，明堂开阔。园区内保持着相对原始的自然环境，山坡之上柿子、红宵梨、山桃板栗等果木啥程度树公墓落于北京市平谷区，建造于大山之间，背靠燕山山脉，面朝水库，西有马鞍山，东有笔架山，明堂开阔。园区内保持着相对原始的自然环境，山坡之上柿子、红宵梨、山桃板栗等果木啥程度树…
              </p>
              <p></p>
            </div>
            <img src="@/assets/images/item_img.png" />
          </div> -->
        </div>
        <div
          class="aboutBoxMainRight1"
          v-else-if="aboutBackNum == 1 && $route.name == '关于韬儒'"
        >
          <!-- <div class="container"> -->
          <!-- <div v-html="'富文本编辑返回文案-韬儒荣耀'"></div> -->
          <div class="aboutBoxMainRightBox" v-if="boo">
            <div
              class="careBoxRightBox"
              v-for="(item, index) in cyList"
              :key="index"
              @click="cyClick(item.dynamicId)"
            >
              <img :src="item.articleThumb" alt="" />
              <div>
                <p>{{ item.articleTitle }}</p>
                <p>
                  {{ item.articleIntro }}
                </p>
                <p><span></span><span></span></p>
              </div>
            </div>
          </div>
          <!-- </div> -->
            <cyText :dynamicId="dynamicId" v-else/>
        </div>
        <div
          class="aboutBoxMainRight"
          v-else-if="aboutBackNum == 2 && $route.name == '关于韬儒'"
        >
          <div class="container">
            <div v-html="staticData1.articleContent"></div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
import cyText from "./cyText";
export default {
  name: "about",
  components: { cyText },
  data() {
    return {
      aboutBackNum: 0,
      aboutBackList: ["关于韬儒", "韬儒产业", "文化介绍"],
      staticData: {},
      staticData1: "",
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      cyList: [],
      dynamicId:0,
      boo:true,
    };
  },
  mounted() {
    this.pageTypeId(1);
    this.pageTypeId(2);
    this.taoruHomePageDynamicList();
  },
  methods: {
    aboutBackClick(e) {
      this.aboutBackNum = e;
      this.boo =true;
    },
    cyClick(id){
      this.dynamicId=id
      this.boo =false;
    },
    pageTypeId(id) {
      api.taoruHomePageStaticDetail({ pageTypeId: id }, (res) => {
        switch (id) {
          case 1:
            this.staticData = res.data;
            break;
          case 2:
            this.staticData1 = res.data || "富文本编辑返回文案-文化介绍";
            break;
        }
      });
    },
    taoruHomePageDynamicList() {
      api.taoruHomePageDynamicList({ pageTypeId: 2 }, (res) => {
        this.cyList = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.about {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  margin-top: 10px;
  .aboutTop {
    border-top: 2px solid #299654;
    padding: 12px 0;
  }
  .aboutTxt {
    width: 153px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    letter-spacing: 2px;
    position: absolute;
    top: 110px;
    left: 19.5%;
  }
  .bread {
    width: 1120px;
    margin: auto;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #929292;
    line-height: 17px;
    letter-spacing: 1px;
    .textColor {
      color: #299654;
    }
  }
  .aboutBan {
    width: 100%;
    height: 200px;
    background: rgba(0, 0, 0, 0.2);
  }
  .aboutBox {
    width: 1120px;
    margin: 20px auto 40px;
    .aboutBoxMain {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .aboutBoxMainLeft {
        width: 220px;
        height: 413px;
        background: #ffffff;
        border-radius: 2px;
        .aboutBoxMainLeftTop {
          width: 220px;
          height: 40px;
          background: #299654;
          border-radius: 2px 2px 0px 0px;
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          letter-spacing: 1px;
          cursor: pointer;
        }
        .aboutBack {
          width: 117px;
          height: 28px;
          position: absolute;
        }
        .mainstyle {
          text-align: center;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #299654;
          line-height: 25px;
          letter-spacing: 1px;
          color: #1a1a1a;
          cursor: pointer;
        }
        .aboutBoxMainLeftmain {
          box-sizing: content-box;
          padding: 28px 52px;
          border-bottom: 1px solid rgba(192, 192, 192, 0.4);
        }
        .zxPhone {
          .icondianhua {
            font-size: 26px;
            margin-right: 4px;
          }
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c0c0c0;
          line-height: 25px;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 40px;
        }
        .zxPhoneNum {
          height: 29px;
          font-size: 24px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #299654;
          line-height: 29px;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 8px;
        }
      }
      .container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 24px;
        background: #ffffff;
        // height: 855px;
        width: 100%;
        height: 100%;
      }
      .aboutBoxMainRight1 {
        width: 860px;
        background: #ffffff;
        border-radius: 2px;
        .aboutBoxMainRightBox {
          width: 100%;
          .careBoxRightBox {
            // width:524px;
            padding: 16px 24px;
            display: flex;
            flex-wrap: nowrap;
            cursor: pointer;
            img {
              width: 287px;
              height: 215px;
            }
            div {
              margin-left: 24px;
              p:nth-child(1) {
                width: 480px;
                height: 33px;
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1a1a1a;
                line-height: 33px;
                letter-spacing: 1px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-top: 16px;
              }
              p:nth-child(2) {
                width: 480px;
                height: 72px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #838383;
                line-height: 24px;
                letter-spacing: 1px;
                margin-top: 40px;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
              p:nth-child(2):hover {
                color: #299654;
              }
              p:nth-child(3) {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                span {
                  height: 24px;
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #838383;
                  line-height: 24px;
                  letter-spacing: 1px;
                }
              }
            }
          }
          .careBoxRightBox {
            border-bottom: 1px solid #ececec;
          }
        }
      }
      .aboutBoxMainRight {
        width: 860px;
        height: 891px;
        background: #ffffff;
        border-radius: 2px;
        .aboutBoxMainRightBox:not(:nth-last-child(1)):before {
          content: "";
          display: block;
          width: 469px;
          height: 1px;
          background: #ececec;
          position: absolute;
          bottom: 0;
        }
        .aboutBoxMainRightBox {
          height: 445px;
          width: 100%;
          padding: 40px 40px 0;
          display: flex;
          justify-content: space-between;
          position: relative;
          .aboutBoxMainRightText {
            p:nth-child(1) {
              height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 33px;
              letter-spacing: 1px;
            }
            p:nth-child(2) {
              margin-top: 20px;
              width: 469px;
              height: 312px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #313131;
              line-height: 24px;
              letter-spacing: 1px;
              //  display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              //  word-wrap: break-word;
              -webkit-line-clamp: 13;
              -webkit-box-orient: vertical;
            }
          }
          img {
            width: 287px;
            height: 215px;
            margin-top: 53px;
          }
        }
      }
    }
  }
}
</style>
